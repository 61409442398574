<script setup>
import axios from 'axios';
import { onMounted, onUnmounted, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import NavbarLayout from '@/layout/NavbarLayout.vue';
import FooterLayout from '@/layout/FooterLayout.vue';

const currentPage = ref(1);
const itemsPerPage = 6;
const router = useRouter();
const courseData = ref([]);
const searchQuery = ref('');
const startDate = ref('');
const endDate = ref('');
const categoryData = ref([]);
const selectedCategory = ref('all');

const courseThumbnailUrl = process.env.VUE_APP_COURSE_THUMBNAIL_URL;
const courseProfilUrl = process.env.VUE_APP_TEACHER_PROFILE_URL;

const fetchCategoryData = async () => {
    try {
        const response = await axios.get('/categories');
        categoryData.value = response.data.slice(-4);
    } catch (error) {
        console.error('Error fetching category data:', error);
    }
};

const fetchCoursesData = async () => {
    try {
        const response = await axios.get('/courses-status');
        courseData.value = response.data;
    } catch (error) {
        console.error('Failed to fetch courses:', error);
    }
};

const filteredCourses = computed(() => {
    let courses = courseData.value;

    // Filter berdasarkan kategori jika kategori bukan "all"
    if (selectedCategory.value !== 'all') {
        courses = courses.filter(course => course.category?.name.toLowerCase() === selectedCategory.value);
    }

    // Filter berdasarkan pencarian
    const filteredBySearch = courses.filter(course =>
        course.title.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        course.description.toLowerCase().includes(searchQuery.value.toLowerCase())
    );

    // Filter berdasarkan tanggal
    if (startDate.value && endDate.value) {
        const selectedStartDate = new Date(startDate.value);
        const selectedEndDate = new Date(endDate.value);

        return filteredBySearch.filter(course => {
            if (course.batches.length > 0) {
                const courseStartDate = new Date(course.batches[0].start_date);
                const courseEndDate = new Date(course.batches[0].end_date);
                return courseStartDate <= selectedEndDate && courseEndDate >= selectedStartDate;
            }
            return false;
        });
    }

    return filteredBySearch;
});

const setCategory = (category) => {
    selectedCategory.value = category;
    currentPage.value = 1; // Reset ke halaman pertama
};

const truncateText = (text, wordLimit = 3) => {
    if (!text) return '';
    const words = text.split(' ');
    return words.length > wordLimit ? `${words.slice(0, wordLimit).join(' ')}...` : text;
};

const totalPages = computed(() => Math.ceil(filteredCourses.value.length / itemsPerPage));

const paginatedCourses = computed(() => {
    const start = (currentPage.value - 1) * itemsPerPage;
    return filteredCourses.value.slice(start, start + itemsPerPage);
});

const goToPage = (page) => {
    if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
    }
};

const handleNavigation = () => {
    const pointers = document.querySelectorAll('.pointer');
    const sections = document.querySelectorAll('.section');

    pointers.forEach(pointer => {
        pointer.addEventListener('click', () => {
            pointers.forEach(p => p.classList.remove('active'));
            sections.forEach(s => s.classList.remove('active'));

            pointer.classList.add('active');
            const targetId = pointer.getAttribute('data-target');
            const targetSection = document.getElementById(targetId);

            if (targetSection) {
                targetSection.classList.add('active');
            } else {
                console.warn(`No section found with ID: ${targetId}`);
            }
        });
    });
};

const showDetailCourse = (id) => {
    router.push(`/detail-courses/${id}`);
};

onMounted(() => {
    fetchCategoryData();
    fetchCoursesData();
    handleNavigation();
});
onUnmounted(() => {
    const pointers = document.querySelectorAll('.pointer');
    pointers.forEach(pointer => {
        pointer.removeEventListener('click', handleNavigation);
    });
});
</script>



<template>
    <!-- NAVBAR START -->
    <NavbarLayout />
    <!-- NAVBAR END -->

    <!-- COURSES START -->
    <section class="bg-about-isi fw-semibold mt-100">
        <div class="container">
            <div class="row">
                <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
                    aria-label="breadcrumb">
                    <ol class="breadcrumb my-4 ms-1">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Courses</li>
                    </ol>
                </nav>
            </div>
        </div>
    </section>

    <section class="coursespage">
        <div class="container my-5">
            <div class="my-4 d-flex justify-content-center">
                <div class="search-input w-100 me-md-1">
                    <input type="text" class="form-control rounded-3 h-43" v-model="searchQuery"
                        placeholder="Type your question..." />
                    <i class="bi bi-search"></i>
                </div>
            </div>
            <div
                class="choose-section d-flex justify-content-between align-items-start h4 mb-4 border-bottom border-success-subtle choose">
                <h5 class="me-3 mt-md-3 opacity-50 pointer fs-16" :class="{ active: selectedCategory === 'all' }"
                    @click="setCategory('all')">All</h5>
                <h5 v-for="category in categoryData" :key="category.id_category"
                    class="me-3 mt-md-3 opacity-50 pointer fs-16"
                    :class="{ active: selectedCategory === category.name.toLowerCase() }"
                    @click="setCategory(category.name.toLowerCase())"> {{ category.name }}</h5>
                <div class="ms-auto d-flex align-items-center">
                    <div class="d-flex flex-column me-3">
                        <label for="startDate" class="form-label fs-12 mb-1 fw-normal">Tanggal Mulai</label>
                        <input type="date" id="startDate" class="form-control rounded-3 mb-1 c-border fs-12"
                            v-model="startDate" @input="currentPage = 1" aria-label="Tanggal Mulai" />
                    </div>
                    <div class="d-flex flex-column">
                        <label for="endDate" class="form-label fs-12 mb-1 fw-normal">Tanggal Berakhir</label>
                        <input type="date" id="endDate" class="form-control rounded-3 mb-1 c-border fs-12"
                            v-model="endDate" @input="currentPage = 1" aria-label="Tanggal Berakhir" />
                    </div>
                </div>
            </div>
            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="row">
                            <div class="col-md-4 shw" v-for="course in paginatedCourses" :key="course.id">
                                <div class="card img-thumbnail p-2 bg-biru mb-4 rounded-4 border-0">
                                    <div class="card w-100 border-0 rounded-4">
                                        <img :src="`${courseThumbnailUrl}/${course.thumbnail}`" class="card-img-top rounded-4" alt="...">
                                        <p class="w-170 text-center fs-12 date" v-if="course.batches.length > 0">
                                            {{ course.batches[0].start_date }} - {{ course.batches[0].end_date }}
                                        </p>
                                        <div class="card-body">
                                            <div class="d-flex align-items-center">
                                                <img :src="`${courseProfilUrl}/${course.teacher?.photo_profile}`" class="rounded-circle me-3" alt="Teacher Photo" height="50px" width="50PX">
                                                <div>
                                                    <p class="fw-medium mb-0 fs-16">{{ course.teacher && course.teacher.user ? course.teacher.user.name : 'No teacher assigned' }}</p>
                                                      <p class="text-muted mb-0">Fronend </p>
                                                </div>
                                            </div>
                                            <a @click="showDetailCourse(course.id_course)" class="garis- text-dark pointer">
                                                <h5 class="mt-4 fs-16 fw-medium">{{ course.title }}</h5>
                                                </a>
                                            <p class="text-justify opacity-75">{{ truncateText(course.description, 20) }}</p>
                                            <div class="d-flex justify-content-around">
                                                <span class="bi bi-clock me-4">
                                                    7 <span>day</span>
                                                </span>
                                                <span class="bi bi-people me-4">80<span>/240</span></span>
                                                <span class="bi bi-star-fill opacity-75"> 4,8</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav aria-label="Page navigation example">
                <ul class="pagination d-flex justify-content-center">
                    <li v-for="page in totalPages" :key="page" :class="['page-item', { active: currentPage === page }]">
                        <a class="page-link" href="#" @click.prevent="goToPage(page)">
                            {{ page }}
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </section>
    <!-- COURSES END -->

    <!-- FOOTER START -->
    <FooterLayout />
    <!-- FOOTER END -->
</template>