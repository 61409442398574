<script setup>

</script>

<template>
    <section class="bg-about questions-index">
        <div class="container">
            <div class="row d-flex justify-content-between">
                <div class="col-md-6 mt-4" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"
                    data-aos-duration="1000">
                    <h3 class="fs-40">Frequently asked questions</h3>
                    <p class="fs-16 mt-4 fw-medium">Check out our list of frequently asked questions regarding courses
                        and
                        services at Digitefa.</p>
                </div>
                <div class="col-md-5 mt-4" data-aos="flip-left" data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000">
                    <div class="accordion" id="accordionPanelsStayOpenExample">
                        <div class="accordion-item mb-2 rounded-3 border border-dark">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed rounded-3 fs-16 fw-normal" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne"
                                    aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                    Bagaimana cara mengakses materinya?
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse">
                                <div class="accordion-body fs-16 fw-lighter opacity-75">
                                    Materi kursus dapat diakses langsung melalui member area setelah Anda mendaftar.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item mb-2 rounded-3 border border-dark">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed rounded-3 fs-16 fw-normal" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo"
                                    aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                    Apakah ada syarat untuk mengikuti kursus?
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse">
                                <div class="accordion-body fs-16 fw-lighter opacity-75">
                                    Tidak ada syarat khusus untuk mengikuti kursus kami. Semua peserta dari berbagai latar belakang bisa mendaftar.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item rounded-3 border border-dark mb-5">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed rounded-3 fs-16 fw-normal" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree"
                                    aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                    Apakah saya akan mendapatkan sertifikat setelah menyelesaikan kursus?
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse">
                                <div class="accordion-body fs-16 fw-lighter opacity-75">
                                    Ya, peserta yang menyelesaikan kursus akan mendapatkan sertifikat sebagai bukti penyelesaian dan kompetensi.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>