<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import ButtonPutihComponent from '@/components/ButtonPutihComponent.vue';
import ButtonHijauComponent from '@/components/ButtonHijauComponent.vue';

const autoScrollInterval = ref(null);

onMounted(() => {
    startAutoScroll();
});

onUnmounted(() => {
    stopAutoScroll();
});

const cards = ref([
    { id: 1, title: 'UI/UX Designer', company: 'Bank Indonesia', location: 'Pati, Indonesia', salary: 'Rp 12.000.000 - Rp 17.000.000', date: '2 Days Ago' },
    { id: 2, title: 'Quality Assurance', company: 'Bank Indonesia', location: 'Jakarta, Indonesia', salary: 'Rp 8.000.000 - Rp 12.000.000', date: '2 Days Ago' },
    { id: 3, title: 'Data Scientist', company: 'Bank Mandiri', location: 'Bandung, Indonesia', salary: 'Rp 15.000.000 - Rp 20.000.000', date: '3 Days Ago' },
    { id: 4, title: 'Frontend Developer', company: 'BCA', location: 'Semarang, Indonesia', salary: 'Rp 13.000.000 - Rp 18.000.000', date: '1 Day Ago' },
    { id: 5, title: 'Backend Developer', company: 'BRI', location: 'Yogyakarta, Indonesia', salary: 'Rp 10.000.000 - Rp 15.000.000', date: '5 Days Ago' },
    { id: 6, title: 'Project Manager', company: 'Bank Danamon', location: 'Medan, Indonesia', salary: 'Rp 20.000.000 - Rp 25.000.000', date: '6 Days Ago' },
]);

const goToNext = () => {
    const container = document.querySelector('.card-container');
    container.scrollBy({ left: container.offsetWidth, behavior: 'smooth' });
};

const startAutoScroll = () => {
    autoScrollInterval.value = setInterval(() => {
        goToNext();
    }, 4000);
};

const stopAutoScroll = () => {
    if (autoScrollInterval.value) {
        clearInterval(autoScrollInterval.value);
        autoScrollInterval.value = null;
    }
};

const goToPrevious = () => {
    const container = document.querySelector('.card-container');
    container.scrollBy({ left: -container.offsetWidth, behavior: 'smooth' });
};
</script>

<template>
    <div class="d-flex align-items-center position-relative">
        <i class="bi bi-chevron-left me-3 carousel-control-custom rounded-2 left" @click="goToPrevious"></i>
        <div class="card-container">
            <div class="card-item" v-for="card in cards" :key="card.id">
                <div class="card p-3 shadow-sm rounded-4" style="min-height: 235px;">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center">
                            <img src="../../../../../assets/images/statistika/bni.png" alt="Logo" width="50"
                                class="me-3" />
                            <div>
                                <h5 class="mb-0 fs-15">{{ card.title }}</h5>
                                <span class="text-muted f-13">
                                    {{ card.company }}, {{ card.location }}
                                </span>
                            </div>
                        </div>
                        <div class="border pointer card-bookmark">
                            <i class="bi bi-bookmark fs-16"></i>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                        <span class="px-3 py-2 d-flex align-items-center card-part-time">
                            <i class="bi bi-clock-fill me-2 text-card-part-time"></i>
                            Part Time
                        </span>
                        <span class="px-3 py-2 d-flex align-items-center card-part-time">
                            <i class="bi bi-geo-alt-fill me-2 text-card-part-time"></i>
                            On Site
                        </span>
                        <span class="px-3 py-2 d-flex align-items-center card-part-time">
                            <i class="bi bi-calendar3 me-2 text-card-part-time"></i>
                            1 - 2 YoE
                        </span>
                    </div>
                    <div class="mt-3">
                        <span class="px-3 py-2 d-flex align-items-center card-gaji">
                            <i class="bi bi-coin me-2 text-card-gaji"></i>
                            {{ card.salary }}
                        </span>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mt-3">
                        <small class="text-dark fs-12">{{ card.date }}</small>
                        <div>
                            <ButtonPutihComponent class="fs-12 me-2">View Detail
                            </ButtonPutihComponent>
                            <ButtonHijauComponent class="fs-12">Apply</ButtonHijauComponent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <i class="bi bi-chevron-right ms-3 carousel-control-custom rounded-2 right" @click="goToNext"></i>
    </div>
</template>

<style scoped>
.card-container {
    display: flex;
    overflow-x: auto;
    gap: 15px;
    padding: 0 20px;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

.card-container::-webkit-scrollbar {
    height: 8px;
}

.card-container::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.card-container::-webkit-scrollbar-track {
    background-color: transparent;
}

.card-item {
    flex: 0 0 calc(50% - 10px);
    scroll-snap-align: start;
    box-sizing: border-box;
}

.carousel-control-custom {
    cursor: pointer;
    font-size: 16px;
    color: #000;
    background-color: rgba(245, 245, 245, 1);
    padding: 7px 12px 7px 12px;
}

.card-bookmark {
    padding: 10px 15px;
    border: 3px solid #D8D8D8;
    border-radius: 10px;
}

.card-part-time {
    border-radius: 10px;
    background-color: #E3FCEC;
    color: green;
    font-size: 12px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.text-card-part-time {
    color: green;
    font-size: 12px;
}

.card-gaji {
    border-radius: 10px;
    background-color: #E0F7FA;
    color: #00796B;
    font-size: 12px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.text-card-gaji {
    color: #00796B;
    font-size: 12px;
}
</style>