<script setup>
import {  onMounted } from 'vue';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

onMounted(() => {
    initializeChart();
});

const initializeChart = () => {
    const ctx = document.getElementById('radarChart').getContext('2d');
    const labels = ['Web Development', 'Software Engineering', 'Cybersecurity', 'Cloud Computing', 'Mobile Development', 'Project Management'];
    const data = {
        labels: labels,
        datasets: [
            {
                backgroundColor: 'rgba(6, 167, 59, 0.5)',
                borderColor: 'rgba(48, 48, 48, 1)',
                borderWidth: 1,
                data: [10, 20, 30, 40, 50, 25],
            },
        ],
    };

    const config = {
        type: 'radar',
        data: data,
        options: {
            responsive: true,
            maintainAspectRatio: true,
            scales: {
                r: {
                    ticks: {
                        stepSize: 10,
                        beginAtZero: true,
                    },
                    min: 0,
                    max: 50,
                    pointLabels: {
                        font: {
                            size: 12,
                        },
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
            },
        },
    }
    new Chart(ctx, config);
};
</script>

<style scoped>
#radarChart {
    max-width: 450px;
    max-height: 450px;
}
</style>
<template>
    <div class="card mt-2 px-3">
        <div class="row">
            <div class="col-md-7" style="margin-top: -60px; margin-bottom: -50px;">
                <canvas id="radarChart"></canvas>
            </div>
            <div class="col-md-5 mt-3">
                <div class="row">
                    <label for="statistics1" class="col-sm-9 col-form-label fs-15">Web
                        Development</label>
                    <div class="col-sm-3 fs-15 mt-2">
                        <span>5000</span>
                    </div>
                </div>
                <div class="row">
                    <label for="statistics2" class="col-sm-9 col-form-label fs-15">Software
                        Engineering</label>
                    <div class="col-sm-3 fs-15 mt-2">
                        <span>4600</span>
                    </div>
                </div>
                <div class="row">
                    <label for="statistics3" class="col-sm-9 col-form-label fs-15">Cybersecurity</label>
                    <div class="col-sm-3 fs-15 mt-2">
                        <span>4390</span>
                    </div>
                </div>
                <div class="row">
                    <label for="statistics4" class="col-sm-9 col-form-label fs-15">Cloud
                        Computing</label>
                    <div class="col-sm-3 fs-15 mt-2">
                        <span>3840</span>
                    </div>
                </div>
                <div class="row">
                    <label for="statistics5" class="col-sm-9 col-form-label fs-15">Mobile
                        Development</label>
                    <div class="col-sm-3 fs-15 mt-2">
                        <span>2109</span>
                    </div>
                </div>
                <div class="row">
                    <label for="statistics6" class="col-sm-9 col-form-label fs-15">Project
                        Management</label>
                    <div class="col-sm-3 fs-15 mt-2">
                        <span>1854</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>